import _ from "lodash";
import moment from "moment";

export const generateInstallments = (receivableAmount, installmentsQtt, firstDueDate, firstNumber) => {
  if (!receivableAmount || !installmentsQtt) return [];
  const installmentsAmount = _.times(installmentsQtt, () => _.floor(receivableAmount / installmentsQtt, 2));
  const receivableAmountDiff = _.round(receivableAmount - _.sum(installmentsAmount), 2);
  for (let i = 0; i < receivableAmountDiff * 100; i++) installmentsAmount[i % installmentsQtt] += 0.01;
  return installmentsAmount.map((valor, index) => ({
    number: firstNumber + index + 1,
    status: "pending",
    amount: valor,
    dueDate: moment(firstDueDate)
      .add(index + 1, "months")
      .toDate(),
  }));
};
