import React, { useCallback, useContext, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import BrazilPhoneInput from "react-telefone-brasileiro";
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCustomToast } from "hooks";
import { EventEmitter, api, yup } from "lib";
import { messages } from "consts";
import { TbExternalLink, TbFileInvoice, TbMessage2Up, TbPigMoney } from "react-icons/tb";
import Context from "./context";
import { MdAdd, MdDeleteOutline, MdEmail, MdRefresh, MdWhatsapp } from "react-icons/md";

const topics = {
  "bankSlip.exempt": "Isenção",
  "bankSlip.created": "Emissão",
  "bankSlip.updated": "Atualização",
  "bankSlip.canceled": "Cancelamento",
  "bankSlip.dueToday": "Lembrete de vencimento",
  "bankSlip.overdue": "Vencido",
};

const Dialog = () => {
  const { label, isOpen, onClose, invoice, charge, customer, isLoadingCustomer, onRefreshCustomer } = useContext(Context);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    const formData = {
      customer: customer._id,
      consumerUnit: charge.consumerUnit?._id || invoice?.consumerUnit?._id,
      invoice: invoice?._id,
      charge: charge._id,
      contacts: _.map(customer.billingContacts, (o) => {
        const tmp = {
          channel: o.sendingMethod || invoice?.sendingMethod,
          contactName: o.name,
          email: o.email,
          whatsapp: o.phoneCategory === "whatsapp" && o.phone,
        };
        tmp.contactTarget = tmp[tmp.channel];
        return tmp;
      }),
    };

    if (charge.isSingle) {
      formData.topic = (() => {
        if (charge.status === "created") return "bankSlip.created";
        if (charge.status === "updated") return "bankSlip.updated";
        if (charge.status === "canceled") return "bankSlip.canceled";
      })();
    } else {
      formData.topic = (() => {
        if (invoice.status === "exempt") return "bankSlip.exempt";
        if (charge.status === "canceled") return "bankSlip.canceled";
        const mask = _.isString(invoice.dueDate) && "DD/MM/YYYY";
        const dueDate = moment(invoice.dueDate, mask).endOf("day").unix();
        const today = moment().endOf("day").unix();
        if (dueDate === today) return "bankSlip.dueToday";
        if (dueDate < today) return "bankSlip.overdue";
        return "bankSlip.".concat(invoice.status);
      })();
    }

    setFormData(formData);
    setFormErrors({});
  }, [invoice, charge, customer, isOpen]);

  const handleSaveData = useCallback(async () => {
    try {
      setIsLoadingSaveData(true);
      const { contacts, ...rest } = formData;
      const data = _.map(contacts, ({ email, whatsapp, ...item }) => ({ ...rest, ...item }));
      await api.put(`/private/messages`, data);
      EventEmitter.emit("messages.refresh");
      toast({ description: messages.success.messagesSendStarted, status: "info", isClosable: true });
      onClose();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, [formData]);

  const handleSubmit = useCallback(async () => {
    try {
      const contactsSchema = yup.object().shape({
        channel: yup.string().required(messages.error.required),
        contactTarget: yup.string().when("channel", {
          is: "email",
          then: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
          otherwise: yup.string().required(messages.error.required),
        }),
      });
      const schema = yup.object().shape({ contacts: yup.array().of(contactsSchema) });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData();
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleChangeContact = useCallback((index, obj) => {
    setFormData((state) => {
      const contacts = [...state.contacts];
      contacts[index] = { ...contacts[index], ...obj };
      return { ...state, contacts };
    });
  }, []);

  const handleAddContact = useCallback(() => {
    setFormData((state) => {
      const contacts = [...state.contacts];
      contacts.push({ channel: invoice.sendingMethod, contactTarget: "" });
      return { ...state, contacts };
    });
  }, [invoice.sendingMethod]);

  const handleRemoveContact = useCallback((index) => {
    setFormData((state) => {
      const contacts = [...state.contacts];
      contacts.splice(index, 1);
      return { ...state, contacts };
    });
  }, []);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
      <ModalOverlay>
        <ModalContent onDoubleClick={(e) => e.stopPropagation()}>
          <ModalHeader as={HStack}>
            <Heading size="md" flex="1">
              {label}
            </Heading>
            <Button
              size="xs"
              variant="outline"
              rightIcon={<Icon as={TbExternalLink} />}
              as={RouterLink}
              to={`/customers/edit/${customer._id}`}
              target="_blank"
            >
              cadastro do cliente
            </Button>
            <IconButton
              size="xs"
              variant="outline"
              icon={<Icon as={MdRefresh} />}
              isLoading={isLoadingCustomer}
              onClick={onRefreshCustomer}
            />
          </ModalHeader>
          <ModalBody>
            <VStack alignItems="stretch" spacing={4}>
              <HStack borderWidth="1px" p="15px" borderRadius="lg" divider={<StackDivider />}>
                <HStack flex="1" justifyContent="center">
                  <Center _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }} w="30px" h="30px" borderRadius="full">
                    <Icon as={TbMessage2Up} />
                  </Center>
                  <Box>
                    <Text fontSize="x-small">Tópico</Text>
                    <Text fontSize="xs" fontWeight="semibold">
                      {topics[formData.topic]}
                    </Text>
                  </Box>
                </HStack>
                {_.isString(invoice?._id) && (
                  <HStack flex="1" justifyContent="center">
                    <Center _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }} w="30px" h="30px" borderRadius="full">
                      <Icon as={TbFileInvoice} />
                    </Center>
                    <Box>
                      <Text fontSize="x-small">Fatura</Text>
                      <Text fontSize="xs" fontWeight="semibold">
                        #{invoice?.nid}
                      </Text>
                    </Box>
                  </HStack>
                )}
                <HStack flex="1" justifyContent="center">
                  <Center _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }} w="30px" h="30px" borderRadius="full">
                    <Icon as={TbPigMoney} />
                  </Center>
                  <Box>
                    <Text fontSize="x-small">Cobrança</Text>
                    <Text fontSize="xs" fontWeight="semibold">
                      #{charge?.nid || "-"}
                    </Text>
                  </Box>
                </HStack>
              </HStack>

              <Divider />
              <HStack>
                <Box flex="1">
                  <Heading size="sm">Destinatários ({_.size(formData.contacts)})</Heading>
                  <Text fontSize="xs">Selecione os destinatários e seus respectivos canais de envio.</Text>
                </Box>
                <Button size="sm" variant="outline" leftIcon={<Icon as={MdAdd} />} onClick={handleAddContact}>
                  adicionar
                </Button>
              </HStack>

              {_.map(formData.contacts, (item, index) => (
                <HStack key={index.toString()}>
                  <FormControl isRequired={true} isInvalid={formErrors.contacts?.[index]?.contactTarget}>
                    <FormLabel fontSize="xs" mb={1}>
                      {item.contactName || "Desconhecido"}
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement>
                        <IconButton
                          size="sm"
                          colorScheme={item.channel === "email" ? "blue" : "green"}
                          icon={<Icon as={item.channel === "email" ? MdEmail : MdWhatsapp} />}
                          onClick={() =>
                            handleChangeContact(
                              index,
                              item.channel === "email"
                                ? { channel: "whatsapp", contactTarget: item.whatsapp }
                                : { channel: "email", contactTarget: item.email }
                            )
                          }
                        />
                      </InputLeftElement>
                      <Input
                        as={item.channel === "whatsapp" ? BrazilPhoneInput : Input}
                        value={item.contactTarget ?? ""}
                        onChange={({ target }) => handleChangeContact(index, { contactTarget: target.value })}
                        temDDD
                        separaDDD
                      />
                      <InputRightElement w="auto" pr="5px">
                        <Button
                          size="sm"
                          variant="outline"
                          leftIcon={<Icon as={MdDeleteOutline} />}
                          onClick={() => handleRemoveContact(index)}
                        >
                          excluir
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{formErrors.contacts?.[index]?.contactTarget}</FormErrorMessage>
                  </FormControl>
                </HStack>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onClose}>
              cancelar
            </Button>
            <Button
              size="sm"
              colorScheme="main"
              isLoading={isLoadingSaveData}
              onClick={handleSubmit}
              isDisabled={_.size(formData.contacts) === 0}
            >
              enviar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default Dialog;
