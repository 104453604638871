import React, { useMemo, useRef, useCallback, useState, Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  StackDivider,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Breadcrumb, ExportCsvStream, Paginator, PermissionedContainer, Portal, TableDrawer, TableEmpty } from "components";
import { useFetchData, useCacheState, useClipboard, useDocumentTitle, usePermissioned, useQueryParams, useTable } from "hooks";
import { EventEmitter, api } from "lib";
import { BankSlipDownload, BankSlipSend, ChargeCancel, ChargeUpdate } from "containers";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import { TbCalendarDue, TbFileExport } from "react-icons/tb";
import { MdMoreHoriz, MdOutlineQrCodeScanner } from "react-icons/md";
import { BiBarcodeReader, BiListUl, BiSupport } from "react-icons/bi";
import { FiClipboard } from "react-icons/fi";
import defaultColumns from "./defaultColumns";
import Filters from "./filters";
import FailedMessages from "./failedMessages";
import { RiAuctionFill } from "react-icons/ri";
import { ChargesCreate } from "./create";

export const ChargesList = () => {
  useDocumentTitle("Cobranças");
  const location = useLocation();
  const { setQueryParams } = useQueryParams();
  const [query, setQuery] = useState();
  const [sort, setSort] = useCacheState(useMemo(() => ({ key: "ChargesList.sort", defaultValue: { createdAt: -1 } }), []));
  const [page, setPage] = useCacheState(useMemo(() => ({ key: "ChargesList.page", defaultValue: 0 }), []));
  const [perPage, setPerPage] = useCacheState(useMemo(() => ({ key: "ChargesList.perPage", defaultValue: 100 }), []));
  const [data, isLoadingData, refreshData, errorData, timestampData, fetchAllPages] = useFetchData(
    useMemo(
      () => ({
        path: "/private/charges",
        params: { query, sort, page, perPage },
        options: { isEnabled: _.isObject(query) },
      }),
      [query, sort, page, perPage]
    )
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 19 }), [location.pathname])
  );
  const { isOpen: isOpenExportData, onOpen: onOpenExportData, onClose: onCloseExportData } = useDisclosure();
  const isAllowedExportSensitiveData = usePermissioned("charges.exportSensitiveData.*");
  const isAllowedReadSensitiveData = usePermissioned("charges.readSensitiveData.*");
  const copyToClipboard = useClipboard();
  const tableDrawerRef = useRef();

  useEffect(() => {
    const listener = EventEmitter.addListener("charges.refresh", refreshData);
    return () => listener.remove();
  }, [refreshData]);

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  const handleExportData = useCallback(
    (visibleColumns) => api.post("/private/charges", { query, sort, isExport: true, visibleColumns }),
    [query, sort]
  );

  return (
    <>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb items={[{ label: "pagadoria" }, { to: "/charges", label: "cobranças" }]} />
            <SlideFade in={true} offsetY="-20px">
              <HStack>
                <ChargesCreate />
                <PermissionedContainer required={["charges.export"]}>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                        mais ações
                      </MenuButton>
                      <Portal>
                        <MenuList fontSize="sm">
                          <PermissionedContainer required="charges.export">
                            <MenuItem onClick={onOpenExportData}>
                              <HStack>
                                <Icon as={TbFileExport} />
                                <Text>exportar cobranças</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Box>
                </PermissionedContainer>
              </HStack>
            </SlideFade>
          </HStack>

          <PermissionedContainer required="messages.read">
            <FailedMessages />
          </PermissionedContainer>

          <Heading my="15px" size="md">
            Cobranças
          </Heading>
          <Filters
            onQuery={setQuery}
            onRefresh={refreshData}
            onPage={setPage}
            isLoading={isLoadingData}
            onTableDrawer={() => tableDrawerRef.current.open()}
          />
        </ContentHeader>

        <ContentBody>
          <Table size="sm" whiteSpace="nowrap">
            <Thead>
              <Tr>
                <Th>#</Th>
                {cells.map(({ accessor, title }) => (
                  <Th key={accessor}>{title}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {_.map(data?.data, (item) => {
                const isPending = _.includes(["created", "updated"], item.status);
                const isActive = _.includes(["created", "updated", "paid"], item.status);
                return (
                  <Tr
                    key={item._id}
                    cursor="pointer"
                    _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}
                    _active={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}
                    onDoubleClick={() => setQueryParams((params) => ({ ...params, charge_id: item._id }), { replace: true })}
                  >
                    <Td>
                      <HStack>
                        <Box>
                          <Menu placement="right-start">
                            <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
                            <MenuList>
                              <MenuGroup title={item.referenceNumber} pb="5px">
                                <VStack spacing={2} divider={<StackDivider />}>
                                  <Fragment>
                                    <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                                      copiar código
                                    </MenuItem>
                                    <PermissionedContainer required="charges.read">
                                      <MenuItem
                                        icon={<Icon as={BiListUl} />}
                                        onClick={() => setQueryParams((params) => ({ ...params, charge_id: item._id }), { replace: true })}
                                      >
                                        detalhes
                                      </MenuItem>
                                    </PermissionedContainer>
                                  </Fragment>
                                  {isActive && (
                                    <BankSlipSend appearance="MenuItem" invoice={item.invoice} charge={item} customer={item.customer} />
                                  )}
                                  {isActive && <BankSlipDownload invoice={item.invoice} charge={item} />}
                                  {isPending && (
                                    <Fragment>
                                      <MenuItem onClick={() => copyToClipboard(item.digitableLine)}>
                                        <HStack>
                                          <Icon as={BiBarcodeReader} />
                                          <Text>copiar linha digitável</Text>
                                        </HStack>
                                      </MenuItem>
                                      <MenuItem onClick={() => copyToClipboard(item.pixInfo.emv)}>
                                        <HStack>
                                          <Icon as={MdOutlineQrCodeScanner} />
                                          <Text>copiar QrCode PIX</Text>
                                        </HStack>
                                      </MenuItem>
                                    </Fragment>
                                  )}
                                  {isPending && !item.isSingle && <ChargeUpdate charge={item} />}
                                  {isPending && <ChargeCancel charge={item} />}
                                </VStack>
                              </MenuGroup>
                            </MenuList>
                          </Menu>
                        </Box>
                        {item.isOverdue && (
                          <Tooltip label="Atrasado">
                            <Center bg="red.500" w="25px" h="25px" borderRadius="full">
                              <Icon as={TbCalendarDue} color="white" />
                            </Center>
                          </Tooltip>
                        )}
                        {item.sentToCollectionAgency && (
                          <Tooltip label="Cobrança terceirizada">
                            <Center bg="purple.500" w="25px" h="25px" borderRadius="full">
                              <Icon as={BiSupport} color="white" />
                            </Center>
                          </Tooltip>
                        )}
                        {(item.invoice?.isProtested || item.invoice?.isSpcClean || item.invoice?.isSpcDirty) && (
                          <Tooltip label="Protesto ou SPC">
                            <Center bg="purple.500" w="25px" h="25px" borderRadius="full">
                              <Icon as={RiAuctionFill} color="white" />
                            </Center>
                          </Tooltip>
                        )}
                      </HStack>
                    </Td>
                    {cells.map(({ accessor, formatter, render }) => (
                      <Td key={accessor}>{formatter?.(item) ?? render?.(item, { isAllowedReadSensitiveData })}</Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot _light={{ bg: "gray.50" }} _dark={{ bg: "gray.900" }} fontWeight="bold">
              <Tr>
                <Td></Td>
                {cells.map(({ accessor, renderFoot }) => (
                  <Td key={accessor}>{renderFoot?.(data?.footer ?? {})}</Td>
                ))}
              </Tr>
            </Tfoot>
          </Table>
          {isLoadingData && (
            <Center p="30px">
              <Spinner />
            </Center>
          )}
          <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} />
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />

      <ExportCsvStream
        filename="cobrancas"
        onStreamData={handleExportData}
        columns={columns}
        isOpen={isOpenExportData}
        onClose={onCloseExportData}
        isAllowedExportSensitiveData={isAllowedExportSensitiveData}
      />
    </>
  );
};
