import React, { useState, useEffect, useCallback, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import {
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { InputCurrency } from "components";
import { currency, yup } from "lib";
import { TbCheck, TbEdit } from "react-icons/tb";
import { IoClose } from "react-icons/io5";
import { generateInstallments } from "./generateInstallments";
import InstallmentsDetailsContext from "./context";
import { messages } from "consts";

const InstallmentAmount = ({ index, value }) => {
  const { formData: parentFormData, setFormData: setParentFormData, isChangeable } = useContext(InstallmentsDetailsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormData((state) => ({ ...state, amount: value }));
  }, [value, isOpen]);

  const handleGetRemainingAmount = useCallback((installments, receivableAmount, index) => {
    const prevInstallments = installments.slice(0, index);
    const remainingAmount = receivableAmount - _(prevInstallments).map("amount").sum();
    return { prevInstallments, remainingAmount };
  }, []);

  const handleSaveData = useCallback(
    (data) => {
      setParentFormData((state) => {
        const installments = [...state.installments];
        installments[index].amount = data.amount;

        const { prevInstallments, remainingAmount } = handleGetRemainingAmount(installments, state.receivableAmount, index + 1);

        const response = { ...state, installments: prevInstallments };

        if (index + 1 === installments.length) {
          response.installments.push({
            number: installments.length + 1,
            status: "pending",
            amount: remainingAmount,
            dueDate: moment(installments[index].dueDate).add(1, "month").toDate(),
          });
        } else {
          const nextinstallments = generateInstallments(
            remainingAmount,
            state.installmentsQtt - (index + 1),
            installments[index].dueDate,
            installments[index].number
          );
          response.installments = [...response.installments, ...nextinstallments];
        }

        response.installmentsQtt = response.installments.length;

        return response;
      });

      onClose();
    },
    [index, handleGetRemainingAmount]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const { remainingAmount } = handleGetRemainingAmount(parentFormData.installments, parentFormData.receivableAmount, index);
      const schema = yup.object().shape({
        amount: yup
          .number()
          .min(0, `${messages.error.moreThan} R$0,00.`)
          .max(remainingAmount, `${messages.error.lessThan} ${currency(remainingAmount)}.`)
          .required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [index, parentFormData.installments, parentFormData.receivableAmount, formData, handleSaveData]);

  return isOpen ? (
    <HStack>
      <IconButton size="sm" variant="outline" icon={<Icon as={IoClose} />} onClick={onClose} />
      <FormControl isInvalid={formErrors.amount}>
        <InputGroup size="sm">
          <InputLeftElement fontSize="xs">R$</InputLeftElement>
          <Input as={InputCurrency} value={formData.amount} onChange={(amount) => setFormData((state) => ({ ...state, amount }))} />
        </InputGroup>
        <FormErrorMessage>{formErrors.amount}</FormErrorMessage>
      </FormControl>
      <IconButton size="sm" colorScheme="main" icon={<Icon as={TbCheck} />} onClick={handleSubmit} />
    </HStack>
  ) : (
    <HStack>
      <Text>{currency(value)}</Text>
      {isChangeable && <IconButton size="xs" variant="outline" icon={<Icon as={TbEdit} />} onClick={onOpen} />}
    </HStack>
  );
};

export default InstallmentAmount;
