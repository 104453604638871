import React, { useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Box, Center, Heading, HStack, Icon, IconButton, Link, StackDivider, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useFetchData, useQueryParams } from "hooks";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdRefresh } from "react-icons/md";
import { BiMailSend } from "react-icons/bi";

const topics = (topic) => {
  switch (topic) {
    case "bankSlip.exempt":
      return "isenta";
    case "bankSlip.created":
      return "emitida";
    case "bankSlip.updated":
      return "atualizada";
    case "bankSlip.dueToday":
      return "próxima do vencimento";
    case "bankSlip.overdue":
      return "vencida";
    default:
      return topic;
  }
};

const FailedMessages = () => {
  const { setQueryParams } = useQueryParams();
  const [_messages, isLoadingMessages, refreshMessages] = useFetchData(
    useMemo(() => {
      const query = {
        message: {
          status: { $nin: ["sending", "delivery"] },
          isNewest: true,
          charge: { $exists: true },
          verificationStatus: "pending",
        },
      };
      return { path: "/private/messages", params: { query, perPage: -1 } };
    }, [])
  );
  const groupedMessages = useMemo(() => _.groupBy(_messages?.data, (o) => o.contactTarget), [_messages?.data]);
  const { isOpen: isOpenMessagesContainer, onToggle: onToggleMessagesContainer } = useDisclosure();
  const [isOpenMessages, setIsOpenMessages] = useState({});

  return (
    _.size(groupedMessages) >= 1 && (
      <Box _light={{ bg: "red.100" }} _dark={{ bg: "gray.900" }} p="20px" borderRadius="lg" my={4}>
        <HStack>
          <Center w="30px" h="30px" borderRadius="full" bg="red.500">
            <Icon as={BiMailSend} color="white" />
          </Center>
          <Box flex="1">
            <Heading size="xs">Mensagens não entregues ({_.size(_messages?.data)})</Heading>
            <Text fontSize="xs">Algumas mensagens não foram entregues a seus destinatários. Por favor, verifique a informação.</Text>
          </Box>
          <IconButton
            size="xs"
            variant="outline"
            colorScheme="red"
            icon={<Icon as={isOpenMessagesContainer ? MdKeyboardArrowUp : MdKeyboardArrowDown} />}
            onClick={onToggleMessagesContainer}
          />
          <IconButton
            size="xs"
            variant="outline"
            colorScheme="red"
            icon={<Icon as={MdRefresh} />}
            isLoading={isLoadingMessages}
            onClick={refreshMessages}
          />
        </HStack>
        {isOpenMessagesContainer && (
          <VStack alignItems="stretch" divider={<StackDivider borderColor="blackAlpha.100" />} spacing={4} mt={4}>
            {Object.entries(groupedMessages).map(([key, items]) => (
              <Box key={key}>
                <HStack>
                  <IconButton
                    size="xs"
                    icon={<Icon as={isOpenMessages[key] ? MdKeyboardArrowUp : MdKeyboardArrowDown} />}
                    onClick={() => setIsOpenMessages((state) => ({ ...state, [key]: !state[key] }))}
                  />
                  <Text fontSize="sm" flex="1">
                    para: <strong>{key}</strong> ({_.size(items)} envios)
                  </Text>
                </HStack>
                {isOpenMessages[key] && (
                  <Box borderLeft="1px" borderLeftColor="blackAlpha.200" ml="12px" mt="4px" pl="20px">
                    {items.map((item) => (
                      <HStack key={item._id} spacing={1}>
                        <Text fontSize="x-small">
                          Cobrança{" "}
                          <Link
                            fontSize="x-small"
                            fontWeight="semibold"
                            onClick={() => setQueryParams((state) => ({ ...state, charge_id: item.charge._id }))}
                          >
                            #{item.charge?.nid}
                          </Link>{" "}
                          {topics(item.topic)}. Enviado em {moment(item.createdAt).format("DD/MM/YYYY")} às{" "}
                          {moment(item.createdAt).format("HH:mm")} por {item.createdBy?.name}.
                        </Text>
                      </HStack>
                    ))}
                  </Box>
                )}
              </Box>
            ))}
          </VStack>
        )}
      </Box>
    )
  );
};

export default FailedMessages;
