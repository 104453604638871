export const contracts = [
  { color: "yellow.500", value: "processing" },
  { color: "purple.500", value: "waiting_signers" },
  { color: "orange.500", value: "waiting_signatures" },
  { color: "gray.300", value: "signed_by_customer" },
  { color: "green.500", value: "finished" },
  { color: "red.500", value: "canceled" },
  { color: "gray.300", value: "archived" },
  { color: "teal.500", value: "editing" },
];

export const consumerUnits = [
  { color: "orange.500", value: "registered" },
  { color: "teal.500", value: "negotiation" },
  { color: "purple.500", value: "under_review" },
  { color: "purple.500", value: "unconfirmed_communication" },
  { color: "purple.500", value: "confirmed_communication" },
  { color: "blue.500", value: "sent_for_connection" },
  { color: "yellow.500", value: "waiting_for_disconnection" },
  { color: "red.500", value: "suspect" },
  { color: "green.500", value: "connected" },
  { color: "yellow.500", value: "waiting_cancellation" },
  { color: "gray.300", value: "canceled" },
];

export const leads = [
  { color: "orange.500", value: "prospection" },
  { color: "blue.500", value: "negotiation" },
  { color: "gray.300", value: "archive" },
];

export const invoices = [
  { color: "gray.300", value: "unqualified" },
  { color: "blue.500", value: "analyzing" },
  { color: "purple.500", value: "validated" },
  { color: "red.500", value: "invalidated" },
  { color: "red.500", value: "issue_failed" },
  { color: "purple.500", value: "processing" },
  { color: "purple.500", value: "canceling" },
  { color: "orange.500", value: "created" },
  { color: "orange.500", value: "updated" },
  { color: "purple.500", value: "updating" },
  { color: "red.500", value: "update_failed" },
  { color: "green.500", value: "paid" },
  { color: "gray.300", value: "exempt" },
  { color: "gray.300", value: "finished" },
];

export const charges = [
  { color: "red.500", value: "issue_failed" },
  { color: "orange.500", value: "created" },
  { color: "green.500", value: "paid" },
  { color: "red.500", value: "canceled" },
  { color: "gray.300", value: "expired" },
  { color: "purple.500", value: "processing" },
  { color: "red.500", value: "failed" },
  { color: "orange.500", value: "updated" },
  { color: "purple.500", value: "updating" },
  { color: "red.500", value: "update_failed" },
  { color: "red.500", value: "cancel_failed" },
  { color: "teal.500", value: "returned" },
  { color: "purple.500", value: "canceling" },
];

export const payments = [
  { color: "gray.300", value: "waiting_approval" },
  { color: "red.500", value: "approval_cancelled" },
  { color: "red.500", value: "approval_failed" },
  { color: "orange.500", value: "created" },
  { color: "green.500", value: "confirmed" },
  { color: "red.500", value: "canceled" },
  { color: "red.500", value: "failed" },
  { color: "gray.300", value: "processed" },
  { color: "purple.500", value: "adjourned" },
  { color: "blue.500", value: "reverted" },
  { color: "teal.500", value: "scheduled" },
];

export const approvals = [
  { color: "orange.500", value: "waiting_analysis" },
  { color: "blue.500", value: "analyzing" },
  { color: "purple.500", value: "validated" },
  { color: "red.500", value: "invalidated" },

  { color: "orange.500", value: "created" },
  { color: "green.500", value: "authorized" },
  { color: "red.500", value: "cancelled" },
  { color: "red.500", value: "failed" },
];

export const webhooks = [
  { color: "orange.500", value: "in_progress" },
  { color: "green.500", value: "success" },
  { color: "red.500", value: "failed" },
  { color: "gray.300", value: "ignored" },
  { color: "blue.500", value: "retried" },
];

export const invoiceCaptures = [
  { color: "orange.500", value: "waiting" },
  { color: "blue.500", value: "in_progress" },
  { color: "gray.500", value: "finished" },
  { color: "red.500", value: "failed" },
  { color: "purple.500", value: "aborted" },
];

export const crons = [
  { color: "orange.500", value: "in_progress" },
  { color: "gray.500", value: "finished" },
  { color: "yellow.500", value: "warned" },
  { color: "red.500", value: "failed" },
];

export const distributorMonitorings = [
  { color: "orange.500", value: "pending" },
  { color: "green.500", value: "paid" },
  { color: "gray.500", value: "archived" },
];

export const messages = [
  { color: "yellow.500", value: "waiting" },
  { color: "yellow.500", value: "sending" },
  { color: "blue.500", value: "sent" },
  { color: "purple.500", value: "bounce" },
  { color: "purple.500", value: "complaint" },
  { color: "green.500", value: "delivery" },
  { color: "red.500", value: "failed" },
  { color: "red.500", value: "error" },
  { color: "purple.500", value: "timeout" },
];

export const messagesVerification = [
  { color: "yellow.500", value: "pending" },
  { color: "green.500", value: "delivered" },
  { color: "gray.500", value: "archived" },
];

export const alerts = [
  { color: "yellow.500", value: "pending" },
  { color: "green.500", value: "solved" },
  { color: "gray.500", value: "archived" },
];

export const alertClassifications = [
  { color: "red.500", value: "danger" },
  { color: "yellow.500", value: "warning" },
  { color: "blue.500", value: "info" },
];

export const clickRecSolicitations = [
  { color: "yellow.500", value: "pending" },
  { color: "green.500", value: "issued" },
  { color: "gray.500", value: "archived" },
];

export const installments = [
  { color: "yellow.500", value: "active" },
  { color: "green.500", value: "completed" },
  { color: "red.500", value: "canceled" },
];
