import React, { Fragment, useCallback, useContext, useState } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { MdOutlineMoveDown } from "react-icons/md";
import ConsumerUnitsContext from "./context";
import { api, masks, yup } from "lib";
import { messages } from "consts";
import { useCustomToast } from "hooks";
import { useNavigate } from "react-router-dom";
import { AsyncSelect } from "components";

let loadCustomersTimeout;

const Migrate = () => {
  const { editSelected, setEditSelected } = useContext(ConsumerUnitsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSavingData, setIsSavingData] = useState(false);
  const toast = useCustomToast();
  const navigate = useNavigate();

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsSavingData(true);
        await api.patch(`/private/consumer-units/${editSelected._id}/migrate/${data.customer}`);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        setEditSelected();
        navigate(`/customers/edit/${data.customer}`);
      } catch (error) {
        toast({ description: error.message, status: "error", isStarted: true });
      } finally {
        setIsSavingData(false);
      }
    },
    [editSelected]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({ customer: yup.string().required(messages.error.required) });
      const data = { ...formData, customer: formData.customer?._id };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const response = await api.post("/private/customers", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name, document }) => ({ _id, name, document })));
    }, 1000);
  }, []);

  return (
    <Fragment>
      <Button size="sm" variant="outline" colorScheme="yellow" rightIcon={<Icon as={MdOutlineMoveDown} />} onClick={onOpen}>
        migrar UC para outro cliente
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Migrar unidade consumidora</ModalHeader>
          <Divider />
          <ModalBody as={VStack} alignItems="stretch" py="30px">
            <Text>
              Deseja realmente migrar a unidade consumidora <strong>{editSelected?.cemigInstallationNumber}</strong> para outro cliente?
            </Text>
            <FormControl isRequired={true} isInvalid={formErrors.customer}>
              <FormLabel>Selecione o cliente</FormLabel>
              <AsyncSelect
                value={formData.customer ?? []}
                defaultOptions
                loadOptions={handleLoadCustomers}
                placeholder="Cliente"
                onChange={(customer) => setFormData((state) => ({ ...state, customer }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ name, document }) => (
                  <Box flex="1">
                    <Text fontSize="sm" fontWeight="500">
                      {name}
                    </Text>
                    <Text fontSize="xs">Documento {masks.document(document)}</Text>
                  </Box>
                )}
                isClearable={true}
              />
              <FormErrorMessage>{formErrors.customer}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="yellow" isLoading={isSavingData} onClick={handleSubmit}>
              confirmar migração
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default Migrate;
