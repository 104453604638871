import React, { useState, useEffect, useMemo, useCallback, memo, useContext } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import * as yup from "yup";
import { messages } from "consts";
import { Breadcrumb, BoxData, PermissionedContainer, InputCurrency, RangeInput, SyncSelect } from "components";
import { DocumentHistory } from "containers";
import { useFetchData, useCustomToast, useDocumentTitle } from "hooks";
import { api } from "lib";
import { MdChevronLeft, MdHistory, MdRefresh } from "react-icons/md";
import { Content } from "pages/Private/Container";
import UserGroupsDetailsContext from "./context";
import Permissions from "./permissions";

export const UserGroupsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar grupo de usuário" : "Nova grupo de usuário");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/user-groups/${_id}` }), [_id]));
  const [formData, setFormData] = useState({ permissions: [] });
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();
  const [contractTemplates, isLoadingContractTemplates, refreshContractTemplates, errorContractTemplates] = useFetchData(
    useMemo(() => ({ path: "/private/contract-templates" }), [])
  );

  useEffect(() => {
    const formData = data ?? { permissions: [], isActive: true };
    setFormData(formData);
  }, [data]);

  useEffect(() => {
    if (errorContractTemplates) toast({ description: errorContractTemplates.message, status: "error", isClosable: true });
  }, [errorContractTemplates]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/private/user-groups/${_id}`, data) : await api.put("/private/user-groups", data);
        navigate(`/user-groups/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        permissions: yup.array().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <UserGroupsDetailsContext.Provider value={{ formData, setFormData, formErrors }}>
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/user-groups")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/user-groups", label: "demandas" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <HStack>
            {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
          </HStack>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Grupo de usuário</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
          <GridItem colSpan={{ base: 4, lg: 2 }}>
            <BoxData label="NID" value={formData.nid ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 8, lg: 10 }}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isInvalid={formErrors.fixedAgreedDiscount}>
              <FormLabel fontSize="sm">Desconto acordado fixo</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.fixedAgreedDiscount ?? ""}
                  onChange={(fixedAgreedDiscount) => setFormData((state) => ({ ...state, fixedAgreedDiscount }))}
                  isPercentage={true}
                />
                <InputRightElement fontSize="xs">%</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.fixedAgreedDiscount}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl>
              <FormLabel fontSize="sm">Desconto</FormLabel>
              <RangeInput
                key={formData._id}
                inputGroupProps={{ size: "md", variant: "" }}
                as={InputCurrency}
                defaultStartValue={formData.discountRangeStart}
                defaultEndValue={formData.discountRangeEnd}
                onChange={({ startValue, endValue }) =>
                  setFormData((state) => ({ ...state, discountRangeStart: startValue, discountRangeEnd: endValue }))
                }
                precision={0}
                isPercentage={true}
                InputRightElement={<InputRightElement fontSize="xs">%</InputRightElement>}
              />
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 12 }}>
            <FormControl>
              <FormLabel fontSize="sm">Templates de contratos</FormLabel>
              <HStack>
                <SyncSelect
                  isMulti
                  placeholder="Selecione"
                  options={contractTemplates || []}
                  value={formData.contractTemplates || []}
                  onChange={(contractTemplates) => setFormData((state) => ({ ...state, contractTemplates }))}
                  getOptionValue={({ id }) => id}
                  formatOptionLabel={({ name }) => name}
                />
                <IconButton
                  variant="outline"
                  icon={<Icon as={MdRefresh} />}
                  isLoading={isLoadingContractTemplates}
                  onClick={refreshContractTemplates}
                />
              </HStack>
            </FormControl>
          </GridItem>
        </Grid>

        <Permissions />
      </Content>

      <PermissionedContainer required={"userGroups.".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate("/user-groups")}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && <DocumentHistory path={`/user-groups/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
    </UserGroupsDetailsContext.Provider>
  );
};
