import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  SlideFade,
  Spinner,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { AsyncSelect, BoxData, Breadcrumb, CustomTab, InputCurrency, PermissionedContainer, StatusBadge } from "components";
import { DocumentHistory } from "containers";
import { useFetchData, useCustomToast, useDocumentTitle, useQueryParams } from "hooks";
import { api, currency, EventEmitter, yup } from "lib";
import { messages } from "consts";
import { MdChevronLeft, MdClose, MdHistory, MdRefresh } from "react-icons/md";
import { Content } from "pages/Private/Container";
import { InvoicesSelect } from "./invoicesSelect";
import { TbExternalLink, TbFileInvoice, TbPigMoney } from "react-icons/tb";
import { generateInstallments } from "./generateInstallments";
import InstallmentAmount from "./InstallmentAmount";
import InstallmentsDetailsContext from "./context";
import InstallmentDueDate from "./InstallmentDueDate";

let loadCustomersTimeout;

export const InstallmentPlansDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar parcelamento" : "Nova parcelamento");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/installment-plans/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const { isOpen: isOpenCreateChargesDialog, onOpen: onOpenCreateChargesDialog, onClose: onCloseCreateChargesDialog } = useDisclosure();
  const { setQueryParams } = useQueryParams();
  const toast = useCustomToast();
  const [activeIndex, setActiveIndex] = useState(0);
  const standaloneInstallments = useMemo(() => _.filter(formData.installments, (o) => !o.charge && !o.invoice), [formData.installments]);
  const isChangeable = useMemo(() => _.filter(formData.installments, (o) => o.charge || o.invoice).length === 0, [formData.installments]);

  useEffect(() => {
    const formData = data ?? { installments: [], invoices: [], installmentsQtt: 1 };
    setFormData(formData);
  }, [data]);

  useEffect(() => {
    if (!_id) {
      setFormData((state) => ({ ...state, receivableAmount: _(formData.invoices).map("amount").sum() }));
      handleGenerateInstallments();
      if (_.size(formData.invoices) === 0) setActiveIndex(1);
    }
  }, [_id, formData.invoices]);

  useEffect(() => {
    const listener = EventEmitter.addListener("charges.refresh", refreshData);
    return () => listener.remove();
  }, [refreshData]);

  const handleGenerateInstallments = useCallback(() => {
    setFormData((state) => {
      const installments = generateInstallments(state.receivableAmount, state.installmentsQtt, moment().add(1, "day"), 0);
      return { ...state, installments };
    });
  }, []);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/private/installment-plans/${_id}`, data) : await api.put("/private/installment-plans", data);
        navigate(`/installment-plans/details/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        customer: yup.string().required(messages.error.required),
        installmentsQtt: yup.number().moreThan(0, `${messages.error.moreThan} 0.`).required(messages.error.required),
        installments: yup.array().min(1, messages.error.required),
        invoices: yup.array().min(1, messages.error.required),
      });
      const data = { ...formData, customer: formData.customer?._id, invoices: _.map(formData.invoices, "_id") };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const response = await api.post("/private/customers", { search, query: { isActive: true }, perPage: 20, isAutocomplete: true });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleRemoveInvoice = useCallback((index) => {
    setFormData((state) => {
      const invoices = [...state.invoices];
      invoices.splice(index, 1);
      return { ...state, invoices };
    });
  }, []);

  const handleCreateCharges = useCallback(async () => {
    try {
      setIsLoadingSaveData(true);
      await api.put(`/private/installment-plans/${_id}/charges`);
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
      onCloseCreateChargesDialog();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, [_id]);

  return (
    <InstallmentsDetailsContext.Provider value={{ formData, setFormData, isChangeable }}>
      <Content>
        <HStack>
          <HStack flex="1">
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/installment-plans")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "pagadoria" },
                { to: "/installment-plans", label: "parcelamentos" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              {_id ? <Heading size="md">Parcelamento #{formData.nid}</Heading> : <Heading size="md">Novo parcelamento</Heading>}
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
          </Box>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={{ base: 12, lg: 12 }}>
            {isChangeable ? (
              <FormControl isRequired={true} isInvalid={formErrors.customer}>
                <FormLabel fontSize="sm">Cliente</FormLabel>
                <AsyncSelect
                  value={formData.customer}
                  defaultOptions
                  loadOptions={handleLoadCustomers}
                  placeholder="Selecione o cliente"
                  onChange={(customer) => setFormData((state) => ({ ...state, customer, invoices: [], installments: [] }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
                <FormErrorMessage>{formErrors.customer}</FormErrorMessage>
              </FormControl>
            ) : (
              <BoxData label="Cliente" value={formData.customer?.name} />
            )}
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 3 }}>
            {isChangeable ? (
              <FormControl isRequired={true} isInvalid={formErrors.installmentsQtt}>
                <FormLabel fontSize="sm">Quantidade de parcelas</FormLabel>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.installmentsQtt || 0}
                  onChange={(installmentsQtt) => {
                    setFormData((state) => ({ ...state, installmentsQtt }));
                    handleGenerateInstallments();
                  }}
                />
                <FormErrorMessage>{formErrors.installmentsQtt}</FormErrorMessage>
              </FormControl>
            ) : (
              <BoxData label="Quantidade de parcelas" value={formData.installmentsQtt} />
            )}
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <FormControl isInvalid={formErrors.receivableAmount}>
              <BoxData label="Valor total do parcelamento" value={currency(formData.receivableAmount || 0)} />
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <FormControl isInvalid={formErrors.receivedAmount}>
              <BoxData label="Valor recebido" value={currency(formData.receivedAmount || 0)} />
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <FormControl isInvalid={formErrors.remainingAmount}>
              <BoxData label="Valor restante" value={currency(formData.remainingAmount || 0)} />
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 12 }}>
            <FormControl isInvalid={formErrors.description}>
              <FormLabel fontSize="sm">Descrição</FormLabel>
              <Textarea
                value={formData.description || ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
              />
              <FormHelperText>A descrição aparecerá no boleto do cliente seguido do número da parcela atual.</FormHelperText>
              <FormErrorMessage>{formErrors.description}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Divider my={8} />

        <Tabs colorScheme="main" isFitted variant="solid-rounded" index={activeIndex} onChange={(index) => setActiveIndex(index)}>
          <TabList>
            <CustomTab
              icon={<Icon as={TbFileInvoice} />}
              title="Parcelas"
              count={_.size(formData.installments)}
              isDisabled={_.size(formData.invoices) === 0}
            />
            <CustomTab icon={<Icon as={TbFileInvoice} />} title="Faturas" count={_.size(formData.invoices)} />
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="20px">
              <Table size="sm" whiteSpace="nowrap">
                <Thead>
                  <Tr>
                    <Th>Número</Th>
                    <Th>Status</Th>
                    <Th>Valor</Th>
                    <Th>Vencimento</Th>
                    <Th>Cobrança</Th>
                    <Th>Fatura</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {_.map(formData.installments, (item, index) => (
                    <Tr key={index.toString()}>
                      <Td>{item.number}</Td>
                      <Td>
                        <StatusBadge schema="invoices" status={item.status} />
                      </Td>
                      <Td>
                        <InstallmentAmount index={index} value={item.amount} />
                      </Td>
                      <Td>
                        <InstallmentDueDate index={index} value={item.dueDate} />
                      </Td>
                      <Td>
                        {item.charge ? (
                          <Button
                            size="xs"
                            variant="outline"
                            rightIcon={<Icon as={TbExternalLink} />}
                            onClick={() => setQueryParams((params) => ({ ...params, charge_id: item.charge._id }))}
                          >
                            <HStack>
                              <StatusBadge schema="charges" status={item.charge.status} />
                              <Text>#{item.charge.nid}</Text>
                            </HStack>
                          </Button>
                        ) : (
                          "-"
                        )}
                      </Td>
                      <Td>
                        {item.invoice ? (
                          <Button
                            size="xs"
                            variant="outline"
                            rightIcon={<Icon as={TbExternalLink} />}
                            as={RouterLink}
                            to={`/invoices/details/${item.invoice._id}`}
                            target="_blank"
                          >
                            <HStack>
                              <StatusBadge schema="invoices" status={item.invoice.status} />
                              <Text>#{item.invoice.nid}</Text>
                            </HStack>
                          </Button>
                        ) : (
                          "-"
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TabPanel>
            <TabPanel px="0" py="20px">
              <HStack justifyContent="space-between" mb={4}>
                <Text fontSize="sm" fontWeight="semibold">
                  {_.size(formData.invoices)} faturas selecionadas
                </Text>
                <InvoicesSelect />
              </HStack>

              {_.size(formData.invoices) === 0 ? (
                <Center paddingTop="40px" paddingBottom="20px">
                  <Box textAlign="center">
                    <Icon as={TbFileInvoice} boxSize={20} marginBottom="10px" />
                    <Text fontSize="lg" fontWeight="bold">
                      Nenhuma fatura selecionada
                    </Text>
                    <Text fontSize="sm">Ainda não foram selecionadas faturas para este parcelamento.</Text>
                  </Box>
                </Center>
              ) : (
                <Table size="sm" whiteSpace="nowrap">
                  <Thead>
                    <Tr>
                      {isChangeable && <Th>#</Th>}
                      <Th>Nid</Th>
                      <Th>Status</Th>
                      <Th>Cod. referência</Th>
                      <Th>N° instalação</Th>
                      <Th>Dt. vencimento</Th>
                      <Th>Valor Click</Th>
                      <Th>#</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {_.map(formData.invoices, (item, index) => (
                      <Tr key={item._id}>
                        {isChangeable && (
                          <Td>
                            <IconButton
                              size="xs"
                              colorScheme="red"
                              icon={<Icon as={MdClose} />}
                              onClick={() => handleRemoveInvoice(index)}
                            />
                          </Td>
                        )}
                        <Td>{item.nid}</Td>
                        <Td>
                          <StatusBadge schema="invoices" status={item.status} />
                        </Td>
                        <Td>{item.referenceCode}</Td>
                        <Td>{item.consumerUnit?.cemigInstallationNumber || "-"}</Td>
                        <Td>{moment(item.dueDate).format("DD/MM/YYYY")}</Td>
                        <Td>{currency(item.amount)}</Td>
                        <Td>
                          <IconButton
                            size="sm"
                            variant="outline"
                            as={RouterLink}
                            target="_blank"
                            to={`/invoices/details/${item._id}`}
                            icon={<Icon as={TbExternalLink} />}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Content>

      <Divider />
      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <PermissionedContainer required={"installmentPlans.".concat(_id ? "update" : "create")}>
            <Tooltip label="Este parcelamento possui cobranças emitidas, portanto não pode ser alterado." isDisabled={isChangeable}>
              <Button
                size="sm"
                colorScheme="main"
                isLoading={isLoadingData || isLoadingSaveData}
                isDisabled={!isChangeable}
                onClick={handleSubmit}
              >
                salvar
              </Button>
            </Tooltip>
          </PermissionedContainer>
          <Button size="sm" variant="ghost" onClick={() => navigate("/installment-plans")}>
            voltar
          </Button>
          <Box flex="1" />
          {_id && (
            <Button
              size="sm"
              variant="outline"
              leftIcon={<Icon as={TbPigMoney} />}
              isDisabled={standaloneInstallments.length === 0}
              onClick={onOpenCreateChargesDialog}
            >
              emitir parcelas
            </Button>
          )}
        </HStack>
      </SlideFade>

      <AlertDialog isOpen={isOpenCreateChargesDialog} onClose={onCloseCreateChargesDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Emitir cobranças</AlertDialogHeader>
          <AlertDialogBody>
            Deseja realmente emitir as cobranças das parcelas {_(standaloneInstallments).map("number").join(", ")}?
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onCloseCreateChargesDialog}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="yellow" isLoading={isLoadingSaveData} onClick={handleCreateCharges}>
              emitir cobranças
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {_id && (
        <DocumentHistory
          path={`/private/installment-plans/${_id}/history`}
          isOpen={isOpenDocumentHistory}
          onClose={onCloseDocumentHistory}
        />
      )}
    </InstallmentsDetailsContext.Provider>
  );
};
