import React, { useState, useEffect, useCallback, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { FormControl, FormErrorMessage, HStack, Icon, IconButton, Input, Text, useDisclosure } from "@chakra-ui/react";
import { yup } from "lib";
import { messages } from "consts";
import { TbCheck, TbEdit } from "react-icons/tb";
import { IoClose } from "react-icons/io5";
import InstallmentsDetailsContext from "./context";
import ReactInputMask from "react-input-mask";

const InstallmentDueDate = ({ index, value }) => {
  const { setFormData: setParentFormData, isChangeable } = useContext(InstallmentsDetailsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState(0);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormData((state) => ({ ...state, dueDate: moment(value).format("DD/MM/YYYY") }));
  }, [value, isOpen]);

  const handleSaveData = useCallback(
    (data) => {
      setParentFormData((state) => {
        const installments = [...state.installments];
        installments[index].dueDate = data.dueDate;

        for (let i = index + 1; i < installments.length; i++) {
          const { dueDate } = installments[index];
          const month = i - index;
          installments[i].dueDate = moment(dueDate).add(month, "month").toDate();
        }

        return { ...state, installments };
      });
      onClose();
    },
    [index]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        dueDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
      });
      const data = { ...formData, dueDate: moment(formData.dueDate, "DD/MM/YYYY").toDate() };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return isOpen ? (
    <HStack>
      <IconButton size="sm" variant="outline" icon={<Icon as={IoClose} />} onClick={onClose} />
      <FormControl isInvalid={formErrors.dueDate}>
        <Input
          as={ReactInputMask}
          mask="99/99/9999"
          value={formData.dueDate}
          onChange={({ target }) => setFormData((state) => ({ ...state, dueDate: target.value }))}
        />
        <FormErrorMessage>{formErrors.dueDate}</FormErrorMessage>
      </FormControl>
      <IconButton size="sm" colorScheme="main" icon={<Icon as={TbCheck} />} onClick={handleSubmit} />
    </HStack>
  ) : (
    <HStack>
      <Text>{formData.dueDate}</Text>
      {isChangeable && <IconButton size="xs" variant="outline" icon={<Icon as={TbEdit} />} onClick={onOpen} />}
    </HStack>
  );
};

export default InstallmentDueDate;
