import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { Box, HStack, Icon, IconButton, StackDivider, Text, VStack } from "@chakra-ui/react";
import { ErrViewer, PermissionedContainer, StatusBadge } from "components";
import { useClipboard } from "hooks";
import { currency, masks } from "lib";
import { TbExternalLink } from "react-icons/tb";
import ChargesDetailsContext from "./context";
import { FiCopy } from "react-icons/fi";

const General = () => {
  const { formData } = useContext(ChargesDetailsContext);
  const copyToClipboard = useClipboard();

  return (
    <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
      {formData.invoice && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Fatura de origem
          </Text>
          <Text>#{formData.invoice?.nid || "-"}</Text>
          <PermissionedContainer required={["invoices.read", "invoices.readOnlySentToCollectionAgency"]}>
            <IconButton
              size="sm"
              variant="outline"
              as={RouterLink}
              icon={<Icon as={TbExternalLink} />}
              to={`/invoices/details/${formData.invoice?._id}`}
              target="_blank"
            />
          </PermissionedContainer>
        </HStack>
      )}

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Status da cobrança
        </Text>
        <StatusBadge schema="charges" status={formData.status} />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Linha digitável
        </Text>
        <Text noOfLines={1} fontSize="xs" maxW="200px">
          {formData.digitableLine || "-"}
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={FiCopy} />} onClick={() => copyToClipboard(formData.digitableLine)} />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Código QrCode PIX
        </Text>
        <Text noOfLines={1} fontSize="xs" maxW="200px">
          {formData.pixInfo?.emv || "-"}
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={FiCopy} />} onClick={() => copyToClipboard(formData.pixInfo.emv)} />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Pagador
        </Text>
        <Box textAlign="right">
          <Text>{formData.customer?.name}</Text>
          <Text fontSize="xs">{masks.document(formData.customer?.document)}</Text>
        </Box>
        <PermissionedContainer required="customers.read">
          <IconButton
            size="sm"
            variant="outline"
            as={RouterLink}
            icon={<Icon as={TbExternalLink} />}
            to={`/customers/edit/${formData.customer?._id}`}
            target="_blank"
          />
        </PermissionedContainer>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Data da criação
        </Text>
        <Text>{moment(formData.createdAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Data do último evento
        </Text>
        <Text>{moment(formData.updatedAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Data de vencimento
        </Text>
        <Text>{moment(formData.dueDate).format("DD/MM/YYYY")}</Text>
      </HStack>

      {formData.paidAt && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Data do pagamento
          </Text>
          <Text>{moment(formData.paidAt).format("DD/MM/YYYY")}</Text>
        </HStack>
      )}

      {formData.paymentMethod && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Método de pagamento
          </Text>
          <Text>{formData.paymentMethod}</Text>
        </HStack>
      )}

      {formData.canceledAt && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Data do cancelamento
          </Text>
          <Text>{moment(formData.canceledAt).format("DD/MM/YYYY")}</Text>
        </HStack>
      )}

      {formData.expiredAt && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Data de expiração
          </Text>
          <Text>{moment(formData.expiredAt).format("DD/MM/YYYY")}</Text>
        </HStack>
      )}

      {formData.failedAt && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Data da falha
          </Text>
          <Text>{moment(formData.failedAt).format("DD/MM/YYYY")}</Text>
        </HStack>
      )}

      {formData.cancelFailedAt && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Data da falha do cancelamento
          </Text>
          <Text>{moment(formData.cancelFailedAt).format("DD/MM/YYYY")}</Text>
        </HStack>
      )}

      {formData.returnedAt && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Data do estorno
          </Text>
          <Text>{moment(formData.returnedAt).format("DD/MM/YYYY")}</Text>
        </HStack>
      )}

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Número do boleto
        </Text>
        <Text>{formData.referenceNumber}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Nosso número
        </Text>
        <Text>{formData.ourNumber}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          ID do boleto
        </Text>
        <Text fontSize="xs">{formData.bankSlipId || "-"}</Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={FiCopy} />} onClick={() => copyToClipboard(formData.bankSlipId)} />
      </HStack>

      <VStack alignItems="stretch">
        <HStack bg="main.100" color="main.900" p="20px" borderRadius="lg">
          <Text flex="1" whiteSpace="nowrap" fontSize="lg">
            Valor do boleto
          </Text>
          <Text fontSize="lg">{currency(formData.amount)}</Text>
        </HStack>

        {formData.amountPaid && (
          <HStack bg="blue.100" color="blue.900" p="20px" borderRadius="lg">
            <Text flex="1" whiteSpace="nowrap" fontSize="lg">
              Valor pago
            </Text>
            <Text fontSize="lg">{currency(formData.amountPaid)}</Text>
          </HStack>
        )}
      </VStack>

      {_.isObject(formData.err) && <ErrViewer err={formData.err} />}
    </VStack>
  );
};

export default General;
